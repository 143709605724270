<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form
          class="select-form"
          ref="queryForm"
          :inline="true"
          :model="queryForm"
          label-width="100px"
          @keyup.enter.native="submitForm"
        >
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select
              clearable
              v-model="queryForm.invoiceType"
              placeholder="请选择发票类型"
              maxlength="32"
            >
              <el-option
                v-for="item in invoiceTypeAllSet"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开票日期" prop="invoiceTime">
            <el-date-picker
              clearable
              class="range-picker"
              v-model="invoiceTimeRange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              range-separator="~"
              type="daterange"
              size="mini"
              value-format="yyyy-MM-dd"
              :picker-options="dateOption"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="发票状态" prop="invoiceStatus">
            <el-select
              clearable
              v-model="queryForm.invoiceStatus"
              placeholder="请选择发票状态"
              maxlength="32"
            >
              <el-option
                v-for="item in invoiceStatusAllSet"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="发票号码"
            prop="invoiceNo"
            v-show="selectExpended"
          >
            <el-input
              clearable
              v-model.trim="queryForm.invoiceNo"
              placeholder="请输入发票号码"
              maxlength="8"
              @keyup.enter.native="submitForm()"
            />
          </el-form-item>
          <el-form-item
            label="发票代码"
            prop="invoiceCode"
            v-show="selectExpended"
          >
            <el-input
              clearable
              v-model.trim="queryForm.invoiceCode"
              placeholder="请输入发票代码"
              maxlength="12"
              @keyup.enter.native="submitForm()"
            />
          </el-form-item>

          <el-form-item
            label="数电票号"
            prop="eInvoiceNo"
            v-show="selectExpended"
          >
            <el-input
              clearable
              v-model.trim="queryForm.eInvoiceNo"
              placeholder="请输入数电票号"
              maxlength="20"
              @keyup.enter.native="submitForm()"
            />
          </el-form-item>

          <el-form-item
            label="销方名称"
            prop="sellerName"
            v-show="selectExpended"
          >
            <el-input
              clearable
              v-model.trim="queryForm.sellerName"
              placeholder="请输入销方名称"
              maxlength="32"
              @keyup.enter.native="submitForm()"
            />
          </el-form-item>

          <el-form-item style="margin-left: 32px">
            <select-button
              show-status
              @list-close="selectExpended = false"
              @list-show="selectExpended = true"
              @select="submitForm"
              @reset="resetForm"
            ></select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="spilt-line"></div>
    <div class="content-main">
      <div class="main-top">
        <p-header :title="'异常发票'">
          <el-button
            type="primary"
            style="float: right"
            @click="ExceptionInvoiceExportBatch"
            >批量导出
          </el-button>
        </p-header>
      </div>
      <div>
        <div class="content-table">
          <el-table
            :data="tableData"
            stripe
            border
            ref="ExceptionInvoiceTable"
            v-loading="dataLoading"
            @selection-change="handleSelection"
            :header-cell-style="handleHeaderCellStyle"
            style="width: 100%"
          >
            <el-table-column
              type="selection"
              fixed="left"
              width="40"
            ></el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="60"
              fixed="left"
            ></el-table-column>
            <el-table-column label="发票类型" min-width="160">
              <template slot-scope="scope">
                <deduct-invoice-type
                  :value="scope.row.invoiceType"
                ></deduct-invoice-type>
              </template>
            </el-table-column>
            <el-table-column
              prop="eInvoiceNo"
              label="数电票号"
              min-width="200"
            />
            <el-table-column
              prop="invoiceCode"
              label="发票代码"
              min-width="160"
              fixed="left"
            />
            <el-table-column
              prop="invoiceNo"
              label="发票号码"
              min-width="160"
              fixed="left"
            />
            <el-table-column
              prop="invoiceTime"
              label="开票日期"
              min-width="180"
            />
            <el-table-column
              prop="sellerName"
              label="销方名称"
              min-width="200"
            />
            <el-table-column label="金额" min-width="120">
              <template slot-scope="scope">
                <span>{{ fmtData(scope.row.amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="税额" min-width="200">
              <template slot-scope="scope">
                <div>
                  <span>{{ fmtData(scope.row.taxAmount) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="有效税额" min-width="200">
              <template slot-scope="scope">
                <div>
                  <span>{{ fmtData(scope.row.deductTaxAmount) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="confirmTaxPeriod"
              label="原所属期"
              min-width="120"
            />
            <el-table-column
              prop="confirmPurpose"
              label="用途"
              min-width="120"
            />
            <el-table-column label="发票状态" min-width="120">
              <template slot-scope="scope">
                <deduct-invoice-status
                  :value="scope.row.invoiceStatus"
                ></deduct-invoice-status>
              </template>
            </el-table-column>
            <el-table-column label="异常类型" min-width="120">
              <template slot-scope="scope">
                <span>{{ fmtInvoiceStatus(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="管理状态" min-width="120">
              <template slot-scope="scope">
                <deduct-invoice-manage-status
                  :value="scope.row.manageStatus"
                ></deduct-invoice-manage-status>
              </template>
            </el-table-column>
            <el-table-column
              prop="checkedTime"
              label="勾选日期"
              min-width="200"
            />
            <el-table-column
              prop="remark"
              label="备注信息"
              show-overflow-tooltip
              min-width="200"
            />

            <el-table-column prop="" label="操作" min-width="120" fixed="right">
              <template slot-scope="scope">
                <div class="operate-button">
                  <el-button type="text" @click="remarkUpdate(scope.row)"
                    >编辑备注</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-statistics" v-loading="sumsLoading">
            <div class="statistics-left">
              <div>
                已选发票：<span style="color: red">{{ selections.length }}</span
                >&nbsp;张
              </div>
            </div>
            <div class="statistics-right">
              <div>发票数量：{{ tableSums.total }}&nbsp;张</div>
              <div>发票税额：￥{{ formatMoney(tableSums.taxAmount) }}</div>
              <div>有效税额：￥{{ formatMoney(tableSums.deductTaxAmount) }}</div>
              <div>发票金额：￥{{ formatMoney(tableSums.amount) }}</div>
            </div>
          </div>
        </div>
        <div class="content-page" v-loading="sumsLoading">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryForm.current"
            :page-sizes="pageSizes"
            :page-size="queryForm.size"
            layout="total, sizes, prev, pager, next"
            :total="tableSums.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      append-to-body
      class="edit"
      title="提示"
      width="30%"
      :visible.sync="visible"
      style="width: 100%; height: 90%"
    >
      <div>
        <span>编辑备注</span>
      </div>
      <div style="padding-top: 20px">
        <el-input
          type="textarea"
          :rows="6"
          v-model="remark"
          placeholder="请输入备注内容"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="doUpdateRemark">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util";
import PHeader from "@/components/pdt/PHeader";
import { tableStyle, pagingMethods, dateOption } from "@/util/mixins";
import {
  pagingDeductInvoiceExceptionList,
  summaryDeductInvoiceException,
  updateExceptionInvoiceRemark,
  exportExceptionInvoice,
} from "@/service/deduction";
import DeductInvoiceType from "@/components/widgets/DeductInvoiceType";
import DeductInvoiceStatus from "@/components/widgets/DeductInvoiceStatus";
import DeductInvoiceManageStatus from "@/components/widgets/DeductInvoiceManageStatus";

export default {
  name: "ExceptionInvoiceQuery",
  components: {
    PHeader,
    DeductInvoiceType,
    DeductInvoiceManageStatus,
    DeductInvoiceStatus,
  },
  mixins: [tableStyle, pagingMethods, dateOption],
  created() {
    this.submitForm();
    this.invoiceTypeSet = this.$store.state.enums.DeductInvoiceType;
    this.invoiceTypeAllSet = this.invoiceTypeSet;
    this.InvoiceStatus = this.$store.state.enums.DeductInvoiceStatus;
    this.invoiceStatusAllSet = this.InvoiceStatus;
  },
  data() {
    return {
      queryForm: {
        //异常发票查询模型
        taxNo: undefined,
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        confirmTaxPeriod: undefined,
        sellerName: undefined,
        checkedTime: undefined,
        eInvoiceNo: undefined,
        invoiceStatus: undefined,
        page: 1,
        size: 30,
      },
      invoiceTimeRange: [],
      dataLoading: false,
      tableData: [], //异常发票列表数据
      queryData: {
        total: 0,
      },
      selectExpended: false, //是否展开查询
      invoiceTypeSet: [], //发票类型枚举
      invoiceTypeAllSet: [],
      invoiceStatusAllSet: [], //发票类型枚举
      visible: false,
      remark: "", //记录编辑备注
      id: "", //记录编辑id
      selections: [],
      sumsLoading: false,
      tableSums: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0,
      },
    };
  },

  methods: {
    //切换分页条数
    handleSizeChange: function (value) {
      this.page.size = value;
      this.submitForm();
    },
    //翻页
    handleCurrentChange(value) {
      this.page.current = value;
      this.submitForm();
    },
    // 修改异常发票备注
    doUpdateRemark() {
      this.visible = false;
      const param = {
        remark: this.remark,
        id: this.id,
      };
      updateExceptionInvoiceRemark(param).then((res) => {
        if (res.success) {
          this.$message.success("修改成功！");
        }
        this.submitForm();
      });
    },
    remarkUpdate(row) {
      this.id = row.id;
      this.remark = row.remark;
      this.visible = true;
    },
    //提交表单
    submitForm() {
      this.queryForm.taxNo = this.$store.state.deductAuthorize.taxNo;
      if (!this.queryForm.taxNo) {
        this.toast("查询失败，选择的组织非纳税主体", "warning");
        return;
      }

      let param = this.getQueryParam();
      this.dataLoading = true;
      pagingDeductInvoiceExceptionList(param).then((res) => {
        if (res.success) {
          this.tableData = res.data.records;
        }
        this.dataLoading = false;
      });

      this.sumsLoading = true;
      summaryDeductInvoiceException(param).then((res) => {
        if (res.success) {
          this.tableSums = res.data;
        }
        this.sumsLoading = false;
      });
    },
    // 计算日期范围（开票日期invoiceTime)
    getQueryParam() {
      let queryParam;
      queryParam = this.addDateRange(
        this.queryForm,
        this.invoiceTimeRange,
        "InvoiceTime"
      );
      return queryParam;
    },
    // 重置表单
    resetForm() {
      this.queryForm = {
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        confirmTaxPeriod: undefined,
        sellerName: undefined,
        checkedTime: undefined,
        eInvoiceNo: undefined,
        invoiceStatus: undefined,
        page: 1,
        size: 30,
      };
      this.invoiceTimeRange = [];
      this.$refs.queryForm.resetFields();
    },
    // 表格批量删除筛选框选中
    handleSelection(val) {
      this.selections = val;
    },
    /** 批量导出 **/
    async ExceptionInvoiceExportBatch() {
      if (this.selections.length == 0) {
        this.toast("请勾选需要导出的信息", "warning");
        return;
      }
      let ids = this.selections.map((i) => i.id);
      this.dataLoading = true;
      const rsp = await exportExceptionInvoice(ids);
      this.downloadFile(rsp);
      this.dataLoading = false;
    },
    fmtInvoiceStatus(row) {
      if (row.invoiceStatus != "ZC") {
        return "发票状态异常";
      }
      if (row.manageStatus != "ZC") {
        return "管理状态异常";
      }
      return "发票状态异常";
    },
    fmtData(data) {
      return "￥" + data;
    },
    formatMoney(value) {
      return fmtMoney(value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/select.scss";

.content-main {
  padding: 0px;
  margin: 0px 0px 0px 0px;
  overflow: hidden;

  .content-table {
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 20px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.select-content {
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}

.spilt-line {
  width: 100%;
  height: 1px;
  background: #e9e9e9;
}

.p-header {
  padding: 0px;
  margin: 12px 0px;
}

::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
</style>
